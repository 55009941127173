import React from 'react'
import { graphql } from 'gatsby'
import type { PageProps } from 'gatsby'
import { NpmTabloidsPage } from 'src/local_modules'
import type { TabloidNbbType } from '@plurix/ecom-pages/src/types/Tabloids'

import devFeatureToggle from '../../devFeatureToggle.json'

interface TabloidSection {
  cmsUrlTabloidNbb?: {
    sections?: Array<{
      data?: TabloidNbbType
    }>
  }
}

const Tabloids = ({ data, location }: PageProps<TabloidSection>) => {
  const tabloidNbbUrl =
    data?.cmsUrlTabloidNbb?.sections?.[0]?.data?.urlTabloidNBB

  const tabloidNbbIsActive = !!(
    devFeatureToggle.useTabloidNbb && tabloidNbbUrl?.length
  )

  return (
    <NpmTabloidsPage
      cid={location?.search}
      tabloidNbbIsActive={tabloidNbbIsActive}
    />
  )
}

export const querySSG = graphql`
  query TabloidsPageQuery {
    cmsUrlTabloidNbb {
      sections {
        data
      }
    }
  }
`

export default Tabloids
